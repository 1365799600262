/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  //Common functionality
  //Sticky years
  var stick = function(stickies) {
    var $thisRowHeight = 0;
    $stickies = stickies.each(function() {
    	//var $thisSticky = $(this).wrap('<div class="year-wrapper" />'); //moved wrapper into html
    	var $thisSticky = $(this);
    	//console.log($thisSticky);
    	//stick for Desktop experience only
    	if (WURFL.form_factor === 'Desktop') {
    		$thisSticky.stick_in_parent().on("sticky_kit:stick", function(e) {
      			//console.log("has stuck!", e.target);
    		}).on("sticky_kit:unstick", function(e) {
    			//console.log("has unstuck!", e.target);
    		});
    	}
    });
  };
    
  var breakpoint = 768; //breakpoint where to switch some elements to mobile layout

  var setupToolTip = function() {
  	var targets = $( '[rel~=tooltip]' ),
        target  = false,
        tooltip = false,
        title   = false;

    targets.bind( 'mouseenter', function()
    {
        target  = $( this );
        tip     = target.attr( 'title' );
        tooltip = $( '<div id="tooltip"></div>' );

        if( !tip || tip === '' ) {
            return false;
        }

        //target.removeAttr( 'title' );
        tooltip.css( 'opacity', 0 )
               .html( tip )
               .appendTo( 'body' );

        var init_tooltip = function()
        {
            if( $( window ).width() < tooltip.outerWidth() * 1.5 ) {
                tooltip.css( 'max-width', $( window ).width() / 2 );
            } else {
                tooltip.css( 'max-width', 230 );
            }

            var pos_left = target.offset().left + ( target.outerWidth() / 2 ) - ( tooltip.outerWidth() / 2 ),
                pos_top  = target.offset().top - tooltip.outerHeight() - 20;

            if( pos_left < 0 ){
                pos_left = target.offset().left + target.outerWidth() / 2 - 20;
                tooltip.addClass( 'left' );
            } else {
                tooltip.removeClass( 'left' );
            }

            if( pos_left + tooltip.outerWidth() > $( window ).width() )
            {
                pos_left = target.offset().left - tooltip.outerWidth() + target.outerWidth() / 2 + 20;
                tooltip.addClass( 'right' );
            } else {
                tooltip.removeClass( 'right' );
            }

            if( pos_top < 0 )
            {
                pos_top  = target.offset().top + target.outerHeight();
                tooltip.addClass( 'top' );
            } else {
                tooltip.removeClass( 'top' );
            }

            tooltip.css( { left: pos_left, top: pos_top } )
                   .animate( { top: '+=10', opacity: 1 }, 50 );
        };

        init_tooltip();
        $( window ).resize( init_tooltip );

        var remove_tooltip = function()
        {
            tooltip.animate( { top: '-=10', opacity: 0 }, 50, function()
            {
                $( this ).remove();
            });

            //target.attr( 'title', tip );
        };

        target.bind( 'mouseleave', remove_tooltip );
        tooltip.bind( 'click', remove_tooltip );
    });
  };


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        //Declare variables // cash DOM elements
  		var menuOpen = false; ///init hamburger and slideout nav menu
  		var resized = false; //has window been resized?
  		var waypoints = []; //array to hold waypoints for grid items
  		

  		//https://coderwall.com/p/q19via/jquery-dom-cache-object
  		DOMCACHESTORE = {};
  		DOMCACHE = {
  			get: function(selector, force) {
  				if (DOMCACHESTORE[selector] !== undefined && force === undefined) {
  					return DOMCACHESTORE[selector];
  				}
  				DOMCACHESTORE[selector] = $(selector);
  				return DOMCACHESTORE[selector];
  			}
  		};

  		var hamburger = DOMCACHE.get('#nav-toggle'); //nav trigger hamburger icon
  		var nav = DOMCACHE.get('#menu'); //primary nav
  		var rail_wrapper = DOMCACHE.get('#rail-wrapper'); //sidebar rail wrapper
  		var rail = DOMCACHE.get('#rail'); //sidebar rail

        //http://git.blivesta.com/animsition/fade-up/
        $("#animsition").animsition({
    		inClass: 'fade-in',
    		outClass: 'fade-out',
    		inDuration: 1500,
    		outDuration: 800,
    		linkElement: '.animsition-link',
    		// e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
    		loading: true,
    		loadingParentElement: 'body', //animsition wrapper element
    		loadingClass: 'animsition-loading',
    		loadingInner: '', // e.g '<img src="loading.svg" />'
    		timeout: false,
    		timeoutCountdown: 5000,
    		onLoadEvent: true,
    		browser: [ 'animation-duration', '-webkit-animation-duration'],
    		// "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
    		// The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
    		overlay : false,
    		overlayClass : 'animsition-overlay-slide',
    		overlayParentElement : 'body',
    		transition: function(url){ window.location.href = url; }
  		});

  		//setup the hamburger menu
		hamburger.on( "click", function() {
			if (menuOpen) {
				nav.width(0);
				hamburger.removeClass('active');
				$('.push').css('position','relative');
				$('.push').css('left','0');
				DOMCACHE.get('#menu').find('li').css('opacity','0');
				menuOpen=false;

			} else {
				DOMCACHE.get('#menu').find('li').css('opacity','1');
				if ($(window).width() <= breakpoint) {
					nav.width('60%');
					$('.push').css('left','60%');
				} else {
					nav.width('35%');
					$('.push').css('left','35%');
				}
				hamburger.addClass('active');
				$('.push').css('absolute','relative');

				menuOpen=true;
			}
		});

  		//Scroll adjustments for all pages except the homepage
  		//get the height of the content sidebar container
  		if (!DOMCACHE.get('body').hasClass('home')) {
			//Sets page header based on viewport size
			var setHeader = function() {
				if ( $(window).width() >= breakpoint ) {
					//for desktop, move page title into siderail
        			page_title = DOMCACHE.get('h1').detach();
            		page_title.insertAfter('.page-header .icon');
            		page_title.css('float','none');
            	} else {
            		//for mobile, move page title next to hamburger
        			page_title = DOMCACHE.get('h1').detach();
            		page_title.insertAfter(hamburger);
            		page_title.css('float','left');
            	}
			};

			//set the rail to only scroll up/down within its container
			$rail_height = rail.height(); //get content height of sidebar


  			//function adjusts footer and sidebar positions and stickiness
  			var adjustStickyRail = function() {
  				if ( ($(window).width() >= breakpoint) && (WURFL.form_factor === 'Desktop') ) {
  					//recalculate sidebar stickiness
  					//stick sidebar rail
  					rail_wrapper.stick_in_parent().on("sticky_kit:stick", function(e) {
  						//on sidebar sticking, add this css, otherwise it's blocking the content below it
  						$(this).parent().children('div').eq(1).css('pointer-events','none');
  					});
  					//recalculate if window has been resized
  					$(document.body).trigger("sticky_kit:recalc");

  					//for desktop, move footer to into side rail
        			rail_footer = $('#rail-footer').detach();
            		rail_footer.prependTo('#rail footer');

  					//set the height to that of widnow or the content height
        			if ($rail_height > $(window).height()) {
        				rail.css('height',$rail_height);
        			} else {
        				rail.css('height',$(window).height());
        			}

        		} else {
        			//remove sticky
        			rail_wrapper.trigger("sticky_kit:detach");
        			//for mobile, move footer to bottom of page
        			rail_footer = $('#rail-footer').detach();
            		rail_footer.prependTo('footer.content-info');
            		//reset rail height
            		rail.css('height','auto');
        		}
        		if ($(window).width() === breakpoint) {
        			//rail_footer.css('margin-left','15px');
        		} 
        		
			};

			var unveil_img = function($items) {
           		//Threshold set to 200px
                //mages to load earlier by 200px before they appear on the screen
           		$($items).unveil(200, function() {
                  $(this).load(function() {
                    //add the loaded class on load which removes the padding we set to
                	//create the masonry layout correctly
                    $(this).parent().parent().addClass('loaded');
                  });
                });
           };

        	//function called below, adds waypoint to each grid item
			//to create animation on viewport entry/exit
			var newWaypoint = function(container) {
				new Waypoint.Inview({
            		element: container,
            		enter: function(direction) {
            			container.removeClass("fadeOutUp");
            			container.removeClass("fadeOutDown");
            			if (direction==="down") {
            				container.addClass("animated fadeInUp");
            			}
            			if (direction==="up") {
            				container.addClass("animated fadeInDown");
            			}
            		},
            		exited: function(direction) {
            			container.removeClass("fadeInUp");
            			container.removeClass("fadeInDown");
            			/*if (direction==="down") {
            				container.addClass("animated fadeOutUp");
            			}
            			if (direction==="up") {
            				container.addClass("animated fadeOutDown");
            			}*/
            		}
            	});
            };
        	//Enter/exit animations for listing page grid items
        	//using the for loop instead of jQuery each to improve performance
        	var setWaypoints = function() {
        		console.log('setWaypoints');
        		var item = DOMCACHE.get('.grid',true).find('.grid-item'),
        			itemLength = item.length,
        			i,
        			$thisparent;
        		for (i = 0; i < itemLength; ++i) {
        			//$thisparent = $(item[i]).parent();
        			var container = $(item[i]);
            		if ( jQuery.inArray( container.attr('id'), waypoints ) !== -1 ) {
            			//console.log('in array');
            		} else {
            			if ( WURFL.form_factor === 'Desktop' ) {
            				//otherwise, add new waypoint for Desktop only
            				newWaypoint(container); //add wapyoint
            				//add element ID to waypoints array, so we don't recreate it
            			} else {
            				//just set animated class to true
            				container.addClass("animated");
            			}	
            			waypoints.push(container.attr('id'));
            		}
            	}
            };


            var loadMore = function() {
            	//The infinite scroll waypoint
            	//Trigger ajaxed pagination when we hit the bottom of the page
       			//Do this on pages .posts-wrapper exists (listing pages only)
       			if (DOMCACHE.get('.posts-wrapper').length) {
       				/*var inview = new Waypoint({
       					element: $('.posts-wrapper')[0],
       					handler: function(direction) {
       						if (!DOMCACHE.get('.ajax-more-posts a' ).hasClass( "no-more-posts" ) &&
 								!DOMCACHE.get('.ajax-more-posts a' ).hasClass( "active" )){
 									DOMCACHE.get('.ajax-more-posts a' ).click();
 									console.log('Triggered load more');
 							}
 									
       					},
       					offset: '50%'
       				});*/
       				
       				var inview = new Waypoint.Inview({
       					element: $('.posts-wrapper')[0],
       					entered: function(direction) {
 							//if the buttons hasn't jsut been clicked and there are more posts to load
 							//trigger the ajax call

 							if (!DOMCACHE.get('.ajax-more-posts a' ).hasClass( "no-more-posts" ) &&
 								!DOMCACHE.get('.ajax-more-posts a' ).hasClass( "active" )) {
 									DOMCACHE.get('.ajax-more-posts a' ).click();
 									console.log('Triggered load more');
 							}
 						},
  					});
  				}
  			};

          	var onLayout = function() {
          		//console.log ('layout complete');
            	setWaypoints(); //re-initialize
            	//we need to manually trigger waypoint refresh it when new items are appended
            	Waypoint.refreshAll();
        	};
        	//Set up masonry options for listing pages
        	var masonryOptions = {
            	// options
            	itemSelector: '.grid-item',
            	gutter: '.gutter-sizer',
            	transitionDuration:0,
            	initLayout: true
            };
            //on a single post page, we can't use the first grid-item for sizing,
            //add columnWidth
        	if (DOMCACHE.get('body').hasClass('single')) {
        		masonryOptions = {
            		// options
            		itemSelector: '.grid-item',
            		gutter: '.gutter-sizer',
            		columnWidth: '.grid-sizer',
            		transitionDuration:0,
            		initLayout: true
            	};
            }



           //Masonry init
           var initGrid = function() {
              var gridMasonry = DOMCACHE.get('.grid');
              gridMasonry.imagesLoaded().always( function( instance ) {
                // bind event listener
                gridMasonry.on( 'layoutComplete', onLayout );
                gridMasonry.masonry(masonryOptions); //init
                unveil_img(DOMCACHE.get('.grid').find('img')); //adds unveil script to load images as they come into viewport
                loadMore(); //sets the waypoint for infinite scroll
                //setup tooltips
                setupToolTip();
              });

              return gridMasonry;
            };
          //trigger on page load and keep reference
          var gridMasonry =  initGrid();




		  //Ajax pagination
           var completeAjax = function(pParamHash) {
              //hide the loading spans
              pParamHash.link.parent().find('span').css('display','none');
              //remove 'active' class
              pParamHash.link.removeClass('active');
              pParamHash.link.removeAttr('class');
              Waypoint.refreshAll(); //reset waypoint
              //recaclulate sticky rail pos
              $(document.body).trigger("sticky_kit:recalc");

			  //setup tooltips
              setupToolTip();


              // Update pagination link
              if ( pParamHash.max_pages ===  pParamHash.page_number) {
                   pParamHash.link.addClass('no-more-posts');
              }
               pParamHash.link.attr('href',  pParamHash.url_split.join('/') +'/'+ (++ pParamHash.page_number));
            };

            //Link triggers loading of additional posts
            DOMCACHE.get('.ajax-more-posts a' ).click(function(e){
          		e.preventDefault();
          		var pParamHash = [];
            	var link = $(this),
            		parent = link.parents('.ajax-more-posts'),
            		url  = link.attr('href');

            	pParamHash.url_split = url.replace(/\/$/, "").split('/');
            	pParamHash.max_pages = Number(parent.attr('data-max-num-pages'));
            	pParamHash.page_number = Number(pParamHash.url_split.pop());

            	var current_page_number = pParamHash.page_number;
            	//add 'active' class to indicate loading in progress, don't trigger it again
            	link.addClass('active');
            	// Loading icon + text
            	link.parent().find('span').css('display','block');
            	//get current listing page category
            	var post_slug = parent.attr('data-post-slug');
            	//get current listing page id
            	var page_id = parent.attr('data-page-id');
            	pParamHash.link = link;
            	
            	//Construct ajax call URL
            	var complete_url = window.location.protocol + "//" + window.location.hostname+'/wp-admin/admin-ajax.php';
                complete_url = complete_url + '?action=hc_getposts'; //function to get the posts
                complete_url = complete_url + '&post_listing_slug='+post_slug; //pass section name
                complete_url = complete_url + '&page_id='+page_id; //pass section name
                complete_url = complete_url + '&paged='+current_page_number;
            	
            	if(post_slug ==='timeline'){
                	//Get year we left off at
                	var current_year = parent.attr('data-current-year');
                	complete_url = complete_url + '&current_year='+current_year;

                	if ( $( "#timeline_year-selector" ).length ) {
                		var timeline_year_selected = $( "#timeline_year-selector" ).val();
                  		if(timeline_year_selected !==''){
                    		complete_url = complete_url + '&era='+timeline_year_selected;
                    		//get the last year printed on page
                  		}
                	}
                }
                 
				if ( $( "#divisions-selector" ).length ) {
					var divisions_selected = $( "#divisions-selector" ).val();
                    if(divisions_selected !==''){
                    	complete_url = complete_url + '&divisions='+divisions_selected;
                    }
                }
                
                //Ajax call, append to hidden div
                  /*$('.posts-wrapper').first().append($('<div class="page-'+current_page_number+'">').load(url + ' .year-section', function(response, status, xhr){
                    //Success!
                    var returnDOM = $.parseHTML(response);
                    var newSetContainer = $( '.page-'+current_page_number );
                    var newSet = $(newSetContainer.find('.year-section .grid'));
                    newSet.imagesLoaded().always( function( instance ) {
                      newSet.on( 'layoutComplete', onLayout );
                      newSet.masonry(masonryOptions);
                      unveil_img(newSet.find('img'));
                      if ($(window).width() > 768) {
                          stick(newSetContainer.find('.year-section h3.year'));
                      }
                    });
                    //slide it up
                    newSetContainer.css('margin-top','-200px');
                    completeAjax(pParamHash);
                  }));*/
                  
                console.log(complete_url);
                $.ajax(complete_url, {
                	success: function(data) {
                		//console.log(params);
                		//Add a new dic to hold the content of of this call
                        var newSetContainer;
                        if(post_slug ==='timeline'){
                        	$('.posts-wrapper').first().append('<div id="page-'+current_page_number+'">');
                			newSetContainer = $('#page-'+current_page_number);
                		}
                		$.each(data, function( index, value ) {
                        	var $content = $( value );
                        	if(post_slug ==='timeline'){
                        		newSetContainer.append($content);
                        	} else {
                        		//All other sections
                        		DOMCACHE.get('.grid').append( $content ).masonry( 'appended', $content );
                          		//unveil
                          		var id = $content.attr('id');
                          		unveil_img($( '#'+id ).find('img'));
                          	}
                        });
                        if(post_slug ==='timeline'){
                        	var newSet = newSetContainer.find('.year-section .grid');
                        	newSet.imagesLoaded().always( function( instance ) {
                        		newSet.on( 'layoutComplete', onLayout );
                      			newSet.masonry(masonryOptions);
                      			unveil_img(newSet.find('img'));
                      			if ($(window).width() >= breakpoint) {
                          			stick(newSetContainer.find('.year-section h3.year'));
                      			}
                      			//Update last year loaded for next set
                      			var current_year = $('#page-'+current_page_number+' .year-section').last().attr('data-year');
                      			DOMCACHE.get('.ajax-more-posts').attr('data-current-year',current_year);
                      		});
                        }
                        
                    },
                    error: function(e) {
                       console.log(e.responseText);
                    },
                    complete: function() {
                       completeAjax(pParamHash);
                    }
                });
            		//Ajax call, append to hidden div
/*            		$('.content').append($('<div class="ajax-post-load">').load(url + ' .grid-item', function(response, status, xhr){
              			//Success!
              			var returnDOM = $.parseHTML(response);
              			//Append grid items from response to masonry
              			var newGridItem = DOMCACHE.get('.ajax-post-load',true).find('.grid-item'),
              				newGridItemLength = newGridItem.length,
              				i;

              			for (i = 0; i < newGridItemLength; ++i) {
              				var grid_item = $(newGridItem[i]);
              				//add page number to this chunk of items
                      		grid_item.addClass('page-'+current_page_number);
                			//append to existing grid
                			DOMCACHE.get('.grid').append( grid_item ).masonry( 'appended', grid_item );
                			//unveil
              				unveil_img(grid_item.find('img'));
              			}
                     	completeAjax(pParamHash);
            		}));*/
            		
          	}); //end ajax pagination
          	
          	
          	//On timeline detail pages, move the posts nav and year page title above the image
          	//in mobile view only
          	var adjustTimelineHeader = function() {
          		if (DOMCACHE.get('body').hasClass('timeline') && DOMCACHE.get('body').hasClass('single')) {
          			postnav = $('.post-nav').detach();
          			header = $('#page-title').detach();
          			if ($(window).width() >= breakpoint) {
          				header.prependTo('.post-content');
          				postnav.prependTo('.post-content');
          			} else {
          				postnav.prependTo('.post-media');
          				header.prependTo('.post-media');
          			}
          		}
          	};



          	//Run resize only once it's done
        	var resizeTimer;
        	$(window).on('resize', function(e) {
        		clearTimeout(resizeTimer);
        		resizeTimer = setTimeout(function() {
        			// Run code here, resizing has "stopped"
        			adjustStickyRail();
          			setHeader();
          			adjustTimelineHeader();
            	}, 250);
            });
        	//also execute on init page load
        	adjustStickyRail();
          	setHeader();
          	adjustTimelineHeader();
          	
          	
          	//Init Scrollframe
          	//scrollFrame('#grid.posts-wrapper a');



        } //end if body case

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var setSpreadHeight = function() {
        	if ($(window).width() > breakpoint) {
        		var spreadHeight = $(window).height() - $('footer').height() - 3;
        		$('main .row.spread').css('height', spreadHeight);
        	} else {
        		$('main .row.spread').css('height', 'auto');
        	}
        };
        
        // Move the logo and site description to top if below 768
        // When the window is resized
        function moveWelcomeMessage() {
        	var welcome_message;
          	if ($(window).width() < breakpoint) {
            	welcome_message = $('#welcome-message').detach();
            	welcome_message.prependTo('main');
          	} else {
          		welcome_message = $('#welcome-message').detach();
            	welcome_message.prependTo('footer');
          	}
        }
        $(window).resize(function() {
        	 moveWelcomeMessage();
        	 setSpreadHeight();
        }).resize();
        //also execute on init page load
        moveWelcomeMessage();
        setSpreadHeight();

        //assign links to sections
        $( ".content-holder" ).each(function(index) {
    		$(this).on("click", function(){
        		// Get the link
       	 		var url = $(this).find("a").attr('href');
       	 		window.location.href = url;
       	 	});
       	});

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Timeline listing page
    'timeline': {
      init: function() {
        // JavaScript to be fired on the timeline page
       	//wrap years with new class
       	$('.content').imagesLoaded().always( function( instance ) {
    		if ($(window).width() > 768) {
       			stick($("h3.year"));
        	}
    	});
        //END Sticky headers

        //Filter by timeline tag
        $('select').change( function () {
          //$('.timeline_year-select').submit();
          	$('html,body').animate({
            	scrollTop: $(this.value).offset().top
            }, 1000);
        });
        
        //unveil images on load
        $('.non-grid img').unveil(200, function() {
        	$(this).load(function() {
            	//add the loaded class on load which removes the padding we set to
                //create the masonry layout correctly
                $(this).parent().parent().addClass('loaded');
            });
        });

      } //End init
    },
    // Harpercollins 200 listing page
    'the_harpercollins_200': {
      init: function() {
        //Filter by division
        $('select').change( function () {
          $('.divisions-select').submit();
        });
      }
    }
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
